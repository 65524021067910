<template>
<div>
    <div class="policy-service" :class="[
      {'animate__animated animate__zoomOutLeft': serviceIsDeleting === service.id,},
      { deleting: isServiceDeleted },
    ]">
        <Loading v-if="serviceLoading" />
        <template v-else> 
            <div class="policy-service__row">
                <div class="policy-service__data sender-data">
                    <div class="sender">
                        <p class="sender-name">{{ service.user.first_name }} {{ service.user.last_name }}</p>
                        <p>{{ formatDate(created)}}</p>

                    </div>

                    <div class="contact">
                        <a v-if="service.user.phone" :href="'tel:'+service.user.phone">
                            <div class="icon icon__phone--active"></div>
                        </a>
                        <div v-else class="icon icon__phone"></div>

                        <a v-if="service.user.email" :href="'mailto:'+service.user.email">
                            <div class="icon icon__mail--active"></div>
                        </a>
                        <div v-else class="icon icon__mail"></div>
                    </div>
                </div>

                <div class="policy-service__data message-data">
                    <p>{{ description }}</p>
                </div>
                <div class="policy-service__data deadline-data medium-data">
                    <p class="deadline">{{ formatDate(deadline) }}</p>
                    <p>{{agent.first_name}} {{agent.last_name}}</p>
                </div>
                <div class="policy-service__data status-data medium-data">
                    <div class="status-indicator" :class="`status-indicator--${status.id}`"> </div>
                    <p>{{ status.name_de}}</p>
                </div>

                <div class="policy-service__data tools-data  ">
                    <div class="icon icon__add tool-icon" @click="showAddReplyPendencyModal(policy ? policy.id : null, service.id, agent);
"></div>
                
                    <div
                        v-if="service.files.length"
                        class="icon icon__pdf--active tool-icon" 
                        @click="openPendencyFilesModal()"
                    >  
                    </div>
                    <div v-else class="icon icon__pdf tool-icon"></div>





                    <div class="icon icon__edit tool-icon" @click="showEditPendencyModal(service)"></div>
                    <div class="icon icon__delete tool-icon" 
                      :class="{ 'icon__delete--active': showDeletePendency }" 
                      tabindex="-1" 
                      @focus="showDeletePendency = true" 
                      @focusout="showDeletePendency = false" ref="delete"
                    > 
                        <transition name="fade">
                            <div class="delete" v-if="showDeletePendency">
                                <div class="delete__text">
                                    Are you sure you want to delete this Pendency?
                                </div>
                                <div class="delete__buttons">
                                    <div class="button button--confirm" @click="deletePendency()"></div>
                                    <div class="button button--cancel" @click="closeDeletePopup()"></div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>

                <div class="policy-service__data arrow-data">
                    <div 
                      class="icon icon__chevron-down-green" 
                      v-if="replies.length > 0" 
                      @click="toggleReplies" :class="showReplies ? 'rotate-chevron' : ''"
                    ></div>
                    <div class="icon icon__chevron-down-gray" v-else style="cursor: initial;"></div>
                </div>
            </div>
        </template>
    </div>

    <div v-if="showReplies">
        <div v-for="reply in replies" :key="reply.id" class="policy-service" :class="[
        {'animate__animated animate__zoomOutLeft': replyIsDeleting === reply.id,},
        { deleting: isReplyDeleted },
      ]">
            <template>
                <div class="policy-service__row">
                    <div class="policy-service__data sender-data">
                        <div class="sender">
                            <p class="sender-name">{{reply.user.first_name}} {{reply.user.last_name}} </p>
                            <p>{{ formatDate(reply.created_at)}}</p>
                        </div>

                        <div class="contact">
                            <a v-if="reply.user.phone" :href="'tel:'+reply.user.phone">
                                <div class="icon icon__phone--active"></div>
                            </a>
                            <div v-else class="icon icon__phone"></div>

                            <a v-if="reply.user.email" :href="'mailto:'+reply.user.email">
                                <div class="icon icon__mail--active"></div>
                            </a>
                            <div v-else class="icon icon__mail"></div>
                        </div>
                    </div>

                    <div class="policy-service__data message-data">
                        <p>{{ reply.description }}</p>
                    </div>
                    <div class="policy-service__data deadline-data medium-data">
                        <p class="deadline">{{ formatDate(reply.deadline) }}</p>
                        <p>{{ reply.agent.first_name }} {{ reply.agent.last_name }}</p>
                    </div>
                    <div class="policy-service__data status-data medium-data">
                        <div class="status-indicator" :class="`status-indicator--${reply.status.id}`"> </div>
                        <p>{{reply.status.name_de}}</p>
                    </div>

                    <div class="policy-service__data tools-data  ">
                        <div class="icon  tool-icon"></div>
                        <div class="icon  tool-icon"></div>
                        <div class="icon icon__edit tool-icon" @click="showEditReplyModal(reply, service.policy ? service.policy.id : null, service.agent)"></div>
                        <div
                          class="icon icon__delete tool-icon"
                          :class="{ 'icon__delete--active': activeReplyId === reply.id }"
                          tabindex="-1"
                          @focus="activeReplyId = reply.id"
                          @focusout="activeReplyId = null"
                        >
                          <transition name="fade">
                            <div class="delete" v-if="activeReplyId === reply.id">
                              <div class="delete__text">
                                Are you sure you want to delete this Reply?
                              </div>
                              <div class="delete__buttons">
                                <div
                                  class="button button--confirm"
                                  @click="deletePendencyReply(reply.id)"
                                ></div>
                                <div
                                  class="button button--cancel"
                                  @click="closeReplyDeletePopup"
                                ></div>
                              </div>
                            </div>
                          </transition>
                        </div>
                    </div>

                    <div class="policy-service__data arrow-data"></div>
                </div>
            </template>
        </div>
    </div>

</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
// import PopupEditer from "@/partials/PopupEditer";
import Loading from "@/partials/Loading";
import moment from "moment";
import statics from "@/statics";
export default {
    name: "PolicyService",

    components: {
        //PopupEditer,
        Loading,
    },

    props: { 
        service: {
            required: true,
            type: Object,
        },
        replies: {
            required: true,
            type: Array,
        },
        policy: {
            required: true,
            type: Object,
        },
        agent: {
            required: true,
            type: Object,
        },
        created: {
            required: true,
            type: String,
        },
        description: {
            required: true,
            type: String,
        },
        deadline: {
            required: true,
            type: String,
        },
        receiver: {
            required: true,
            type: Object,
        },
        status: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            showDeletePendency: false,
            showDeleteReply: false,

             activeReplyId: null,

            

            showReplies: false,

            showIntervalPopup: false,
            showStartDatePopup: false,
            showEndDatePopup: false,
            serviceLoading: false,
            serviceIsDeleting: "",
            replyIsDeleting: "",
            isServiceDeleted: false,
            isReplyDeleted: false,  
        };
    },

    computed: {
        ...mapGetters(["policyForms", "language"]),
        code() {
            return this.service.service ?
                this.service.service.code || statics.empty :
                null;
        },

        unformattedStartDate() {
            return this.service.start_date || statics.empty;
        },

        unformattedEndDate() {
            return this.service.end_date || statics.empty;
        },

        startDate() {
            return this.service.start_date ?
                moment(this.service.start_date.split("T")[0]).format("DD.MM.YYYY") :
                statics.empty;
        },

        endDate() {
            return this.service.end_date ?
                moment(this.service.end_date.split("T")[0]).format("DD.MM.YYYY") :
                statics.empty;
        },

        nettoPremium() {
            return this.service.netto_premium ?
                `${this.service.netto_premium} .-CHF` :
                statics.empty;
        },

        bruttoPremium() {
            return this.service.bruto_premium ?
                `${this.service.bruto_premium} .-CHF` :
                statics.empty;
        },

        premiumInterval() {
            return this.service.premium_interval ?
                this.service.premium_interval.name || statics.empty :
                statics.empty;
        },

        dueDate() {
            return statics.empty;
        },

        name() {
            if (this.service.service_model) {
                return this.service.service_model.name
            }
            return this.service.service ?
                this.service.service[`long_${this.language}`] || statics.empty :
                statics.empty;
        },

        plateNumber() {
            return this.service.plate_number || statics.empty;
        },

        intervals() {
            return this.policyForms.intervals;
        },

        isPaid() {
            if (this.service && this.service.policy_service_payments && this.service.policy_service_payments.length > 0) {
                return this.service.policy_service_payments.every(payment => payment.paid == true);
            }
            return false;
        },

        isPaymentEntered() {
            if (this.service && this.service.policy_service_payments && this.service.policy_service_payments.length > 0) {
                return this.service.policy_service_payments.every(payment => payment !== null && payment !== undefined);
            }
            return false;
        },
    },

    methods: {
        showEditPendencyModal(service){ 
            this.$store.commit("setShowAddPendencyModal", true); 
            this.$store.commit("setIsPendecyEditing", true); 
            this.$store.commit("setPendencyEditData", service);
        },


        showEditReplyModal(reply, policyId, agent){ 
            this.$store.commit("setShowAddPendencyModal", true); 
            this.$store.commit("setIsReplyEditng", true);
            this.$store.commit("setPendencyForReply", true); 
            this.$store.commit("setReplyEditData", {reply, policyId});
            this.$store.commit("setPendencyReplyAgentData", agent);
        },
  


        toggleReplies() {
            this.showReplies = !this.showReplies;
            this.$emit('toggle-replies');
        },

        showAddReplyPendencyModal(policyId, serviceId, agent) { 
            this.$store.commit("setPendencyForReply", true);
            this.$store.commit("setShowAddPendencyModal", true);
            this.$store.commit("setPendencyReplyData", {
              policyId: policyId,
              serviceId,
              agent
            });

        },

        formatDate(dateString) {
            if (!dateString) return '';

            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}.${month}.${year}`;
        },

        async deletePendency() {
            const pendencyId = this.service.id;
            const customerId = this.$route.params.id;

            this.serviceIsDeleting = this.service.id;

            this.closeDeletePopup();
            await this.$store.dispatch("deletePendencies", {
                pendencyId,
                customerId
            });
            this.isServiceDeleted = true;
        },

        closeDeletePopup() {
            this.$refs.delete.blur();
            this.showDeletePendency = false;
        },

        async deletePendencyReply(id) {
            const replyId = id;
            const customerId = this.$route.params.id;
            this.replyIsDeleting = id;
            this.closeReplyDeletePopup();
            await this.$store.dispatch("deleteReplyPendencies", {
                replyId,
                customerId
            });
            this.isReplyDeleted = true;

            this.toggleReplies = false;
        },
 
        closeReplyDeletePopup() {
            this.$refs.delete.blur();
            this.activeReplyId = null;  
        }, 

        openPendencyFilesModal() { 
            this.$store.commit("setShowFilesEditModal", true);
            this.$store.commit("setFilesToEditId", this.service.id);
            this.$store.commit("setFilesToEdit", this.service.files); 
            this.$store.commit("setFilesEditMode", 'Pendencies'); 
        }, 
    },
};
</script>

<style scoped>
.policy-service__data {
    flex: initial !important;
}

.replies-row {
    margin-top: 14px;
}

.sender-data {
    /* 
  width: 200px !important; */
    justify-content: space-between;
}

.sender-data,
.sender,
.contact {

    display: flex;

}

.sender {
    flex-direction: column;
    justify-content: center;
    color: #363636;
}

.sender-name,
.deadline {
    margin-bottom: 5px;
}

.contact {
    margin-right: 20px;
    gap: 15px;
}

.message-data p {
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    margin: 0% !important;
    margin-left: 0;
    height: 90%;
    width: 100%;
    font-size: 14px;
}

.deadline-data {
    flex-direction: column;
    color: #363636
}

.status-indicator {
    width: 16px;
    height: 16px;

    border-radius: 10px;
}

.status-indicator--1 {
    background-color: #FF9E0F;
}

.status-indicator--2 {
    background-color: #ff3333;
}

.status-indicator--3 {
    background-color: #22db8e;
}

.status-data {
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
}

.tools-data {
    justify-content: space-around;
}

.tool-icon {
    border: 1px solid transparent;
    height: 100%;
    width: 25%;
    border-left-color: #7F8FA426;
}

.icon__delete {
    border-right-color: #7F8FA426;
}

.sender-data {
    width: 22%;
}

.message-data {
    width: 35%;
}

.medium-data {
    width: 14%;
    justify-content: center;
    text-align: center;
}

.tools-data {
    width: 12%;
    justify-content: center;
    text-align: center;
}

.arrow-data {
    width: 3%;
    justify-content: center;
}

.rotate-chevron {
    transform: rotate(180deg);
}
</style>
